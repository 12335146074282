import React, { useState, useCallback } from 'react';
//import { ReactComponent as Record } from '../assets/svg/record.svg';
import { ReactComponent as Share } from '../assets/svg/share.svg';
import { ReactComponent as Heart } from '../assets/svg/heart.svg';
import { ReactComponent as HeartFilled } from '../assets/svg/heart_filled.svg';
import { ReactComponent as Download } from '../assets/svg/download.svg';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { playGame, likeVideo, VideoItem, unlikeVideo } from '../store/screenSlice';
import { Drawer } from "./Drawer";


export default function VideoActions({ item }: { item: VideoItem }) {
  const dispatch = useDispatch();

  //Drawer Share
  const [open, setOpenShare] = useState(false);
  const handleOpenShare = useCallback(() => setOpenShare(true), []);
  const handleCloseShare = useCallback(() => setOpenShare(false), []);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {/*<VideoAction count={item.playCount}>
          <img src={process.env.PUBLIC_URL + '/images/play_button.png'} className="playButton" alt="button" onClick={() => dispatch(playGame(item.id))}></img>
      </VideoAction> */} 
        <VideoAction count={item.likeCount}>
          {item.liked ? (
            <HeartFilled
              onClick={() => dispatch(unlikeVideo(item.id))}
              height={40}
              width={40}
              fill={'#FF0028'}
            />
          ) : (
            <Heart
              onClick={() => dispatch(likeVideo(item.id))}
              height={40}
              width={40}
              fill={'#fff'}
            />
          )}
        </VideoAction>
        <VideoAction count={item.commentCount}>
          <Download height={35} width={35} fill={'#fff'} />
        </VideoAction>
        <VideoAction count={item.shareCount}>
          <Share height={35} width={35} fill={'#fff'} onClick={handleOpenShare}/>
        </VideoAction>
        <Drawer anchor="right" open={open} onClose={handleCloseShare} gameUrl={item.gameUrl}/>
        
        { /*<Record
          height={40}
          width={40}
          className="rotatingRecord"
          style={{
            marginTop: 30,
          }}
        /> */}
      </div>
    </div>
  );
}

function VideoAction(props: { children: any; count: number }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '.8em',
        marginTop: 20,
      }}
    >

      <div
        style={{
          cursor: 'pointer',
        }}
      >
        {props.children}
      </div>
      <div
        style={{
          marginTop: 5,
        }}
      >
        {Number.isNaN(props.count) ? null : props.count}
      </div>
    </div>
  );
}
