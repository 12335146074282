import React from 'react';
// eslint-disable-next-line
import { playGame, VideoItem, selectMaxDownloaded } from '../store/screenSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function VideoDetails({ item }: { item: VideoItem }) {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const showMaxDownloaded = useSelector(selectMaxDownloaded);

  return (
    <div 
      style={{
        display: 'flex',
        alignContent: 'flex-end',
        flexDirection: 'column-reverse',
      }}
    >
      <div onClick={(e) => e.stopPropagation()} 
        style={{
          background: '#00000038',
          padding: '5px',
          marginBottom: '30px',
      }}>
        <div
          style={{
            fontSize: '1.0em',
            fontWeight: 'bold',
          }}
        >
          <h3>{item.name}</h3>
        </div>
        <div style={{ marginTop: 10, fontSize: '0.8em', textAlign: 'justify'}}>
          {item.description_es}{' '} 
          <div>
            {item.hashtags.map((name) => (
              <span key={name}>
                <strong>#{name}</strong>{' '}
              </span>
            ))}
          </div>
          {showMaxDownloaded ? 
          /*(<div className="maxDownloadWarning">HAS SUPERADO EL LIMITE DE DESCARGAS DIARIA. <br />ESPERA 24 HORAS.</div>) : ( <div className="downloadLabel" onClick={() => dispatch(playGame(item.id))}>DESCARGA GRATIS AQUI!</div>)  */
          (<div className="maxDownloadWarning">HAS SUPERADO EL LIMITE DE DESCARGAS DIARIA. <br />ESPERA 24 HORAS.</div>) : ( null )  
          }
        </div>
      </div>
    </div>
  );
}
